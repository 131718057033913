<template>
  <svg
    width="126"
    height="97"
    viewBox="0 0 126 97"
    stroke="#191617"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="48.2637"
      y="1.58579"
      width="66.0127"
      height="66.0127"
      transform="rotate(45 48.2637 1.58579)"
      stroke-width="2"
    />
    <rect
      x="77.7363"
      y="1.58579"
      width="66.0127"
      height="66.0127"
      transform="rotate(45 77.7363 1.58579)"
      stroke-width="2"
    />
  </svg>
</template>
